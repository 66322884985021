.app {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #E5E5E5;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.cardContainer {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 80%;
  margin: auto;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  border-radius: 20px;
}

.signInContainer{
  justify-content: center;
  margin: 20px;
  align-content: center;
  display: flex;
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
}

.signInButton {
  height: 50px;
  width: 400px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}