.tableCardContainer {
  margin: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 20px;
}

.tableCard {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-radius: 20px;
  display: flex;
  max-height: 1000px;
}

.tableHead {
  background-color: #1d5890;
  border-radius: 20px;
  flex: 1;
}

.tableHeadText {
  color: white;
}

.MuiTableCell-head {
    color: rgb(255, 255, 255)!important;
    font-weight: 500;
    line-height: 1.5rem;
}
.MuiTableHead-root {
    display: table-header-group;
    border-radius: 20px;
}
.MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    border-radius: 20px;
}