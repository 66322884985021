.buttonLayoutContainer {
    display: flex;
    justify-content: center;
}

.buttonLayoutRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
}

.buttonLayoutRowMiddle{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.layoutButton {
    height: 50px;
    width: 200px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin: 10px;
    cursor: pointer;
}

.layoutButtonText{
    color: white;
    font-size: 24px;
    text-align: center;
    line-height: 0px;
    user-select: none;
}

.layoutCancelButton {
    background-color: #993220;
}

.layoutNormalButton {
    background-color: #1d5890;
}

.layoutConfirmButton {
    background-color: #11995F;
}

.layoutDisabledButton {
    background-color: #E5E5E5;
}