.headerContainer {
  background-color: #1d5890;
  color: white;
}

.headerText {
  font-size: 2.5rem;
  padding: 0.5rem;
  margin: auto;
  user-select: none;
  text-align: center;
}

.circle {
  border-radius: 50%;
  background-color: #4592d9;
  margin: 10px;
  width: 40px;
  max-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.circleText {
  font-size: 1.9rem;
  padding: 0.1rem;
  margin: auto;
  user-select: none;
  text-align: center;
}

.headerRow {
  display: flex;
  justify-content: space-between;
}
