.collapsibleCardContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.collapsibleCard {
  background-color: white;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-radius: 20px;
  margin: 20px;
  max-width: 350px;
  cursor: pointer;
}
.collapsibleCardTriggerHeader {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.collapsibleCardTriggerHeader {
  height: 55px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}
.collapsibleCardTriggerHeaderText {
  font-size: 24px;
  text-align: center;
  margin: auto;
  flex: 0.6;
  user-select: none;
}

.WOLeft {
  border-radius: 50%;
  background-color: #cfcfc4;
  margin: 10px;
  width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  align-self: flex-end;
  flex: 0.2;
}

.WOLeftClear {
  border-radius: 50%;
  background-color: #77dd77;
  margin: 10px;
  width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  align-self: flex-end;
  flex: 0.2;
}

.WOLeftNotClear {
  border-radius: 50%;
  background-color: #ffb347;
  margin: 10px;
  width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  align-self: flex-end;
  flex: 0.2;
}

.WOLeftClosed {
  border-radius: 50%;
  background-color: #ff483f;
  margin: 10px;
  width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  align-self: flex-end;
  flex: 0.2;
}

.collapsibleCardCircle {
  border-radius: 50%;
  background-color: #4592d9;
  margin: 10px;
  width: 40px;
  max-width: 40px;
  height: 40px;
  cursor: pointer;
  align-self: flex-end;
  flex: 0.2;
}

.collapsibleCardCircleWO {
  border-radius: 50%;
  background-color: #4592d9;
  margin: 10px;
  width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  align-self: flex-end;
  flex: 0.2;
}

.collapsibleCardCircleTextWO {
  font-size: 1.5rem;
  margin: auto;
  user-select: none;
  text-align: center;
  color: white;
}

.collapsibleCardCircleText {
  font-size: 1.9rem;
  padding: 0.1rem;
  margin: auto;
  user-select: none;
  text-align: center;
  color: white;
}

.collapsibleCardTriggerOpen {
  background-color: #1d5890;
  border-radius: 20px;
}

.woText {
  font-size: 24;
  flex: 0.6;
  user-select: none;
}

.redText {
  color: red;
}
