.projectCardContainer {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-radius: 20px;
  margin: 20px;
  max-height: 200px;
}

.projectCardHeader {
  background-color: #1d5890;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  user-select: none;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.projectCardHeaderText {
  font-size: 36px;
  margin: 0px;
  margin-bottom: 30px;
  position: absolute;
}
.projectCircle {
  border-radius: 50%;
  background-color: #4592d9;
  width: 40px;
  max-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
  margin-left: auto;
}

.projectCircleText {
  font-size: 1.9rem;
  margin: auto;
  user-select: none;
  text-align: center;
}

.closeButton {
  background-color: #993220;
  height: 50px;
  width: 200px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  flex: 1;
  position: absolute;
  bottom: 0px;
}

.closeButtonText {
  color: white;
  font-size: 24px;
  text-align: center;
  user-select: none;
}
