.searchBarContainer {
  display: flex;
}

.searchCard {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-width: 200px;
  min-height: 50px;
  flex-direction: column;
}

.searchInput {
    border-radius: 20px;
    border-width: 1px;
    width: 90%;
    height: 50%;
}

.searchText {
    margin: 0px;
    padding: 0px;
    text-align: left;
}