.technicianCardContainer {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-radius: 20px;
  margin: 20px;
  max-height: 200px;
  height: 200px;
}

.technicianCardHeader {
  background-color: #1d5890;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: -25px;
  user-select: none;
}

.technicianCardHeaderText {
    font-size: 36px;
    margin: 0px;
    margin-bottom: 30px;
}
