.technicianCardContainer {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-radius: 20px;
  margin: 20px;
}

.toolCardHeader {
  background-color: #1d5890;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0px;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
}

.toolLeft {
  flex: 0.6;
}

.toolCardHeaderText {
  text-align: center;
  font-size: 36px;
  margin: 0px;
  margin-bottom: 30px;
}

.maxHeight {
  height: 270px;
  max-height: 400px;
}

.toolCircle {
  border-radius: 50%;
  background-color: #4592d9;
  width: 40px;
  max-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
  margin-left: auto;
}

.toolCircleText {
  font-size: 1.9rem;
  margin: auto;
  user-select: none;
  text-align: center;
}

.searchInputTool {
  border-radius: 20px;
  border-width: 1px;
  width: 50%;
  height: 50%;
}
.toolRow {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
}