.reportBugPageContainer {
  padding-bottom: 20px;
}

.reportBugContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.reportBugCard {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-radius: 20px;
  margin: 20px;
  max-height: 600px;
  height: 350px;
}

.reportBugCardHeader {
  background-color: #1d5890;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: -25px;
  user-select: none;
}

.reportBugCardHeaderText {
  font-size: 36px;
  margin: 0px;
  margin-bottom: 30px;
}

.reportBugCardLabel {
    flex-direction: column;
    border-radius: 20px;
    border-width: 1px;
}
