.homeContainer {
  flex: 1;
  padding: 30px;
}

.homeText {
  font-size: 2.5rem;
  padding: 0.5rem;
  margin: auto;
  user-select: none;
  text-align: center;
  color: black;
}

.imageLogo {
  height: 100%;
  padding: 1em;
  user-select: none;
  width: 40%;
}

.button {
  background-color: #1d5890;
  color: white;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 100px;
  border-radius: 20px;
  margin: 20px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #2063a1;
  }
  &:active {
    background-color: #1d5890;
  }
}

.buttonText {
  text-align: center;
  font-size: 30px;
  user-select: none;
}

.homeRow {
  display: flex;
  justify-content: center;
}
