.popUpContainer {
  position: fixed;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  max-width: 50%;
  max-height: 70%;
  justify-content: center;
  align-items: center;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  overflow-y: scroll;
  z-index: 5000;
}

.popUpBackground {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.popUpHeader {
  background-color: #1d5890;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 50px;
  top: 0;
}

.popUpHeaderText {
  color: white;
  font-size: 36px;
  text-align: center;
  margin: 0px;
  user-select: none;
}

.popUpContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
}