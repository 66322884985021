.myToolPageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.myToolButton {
  height: 50px;
  width: 200px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  background-color: #1d5890;
  justify-self: center;
  align-self: center;
}

.myToolButtonText {
  color: white;
  font-size: 24px;
  text-align: center;
  line-height: 0px;
  user-select: none;
}
.myToolsRow {
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
