.inputFieldContainer {
    width: 100%;
    padding: 5px;
    padding-top: 0px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    margin-bottom: -20px;
  }
  .inputText {
    font-size: 18px;
    color: black;
    margin-bottom: 0px;
    padding-bottom: 0px;
    text-align: left;
    margin-left: 35px;
    margin-bottom: -10px;
    user-select: none;
  }
  
  .inputOutline {
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 0 1pt grey;
    outline: none;
    height: 100px;
    width: 80%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    resize: none;
    padding: 5px;
  }
  .inputInformation {
    font-size: 14px;
    margin-left: 5px;
    text-align: left;
    user-select: none;
  }
  