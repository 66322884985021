.takeToolPageContainer {
  padding-bottom: 20px;
}

.takeToolContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.redText {
  color: red;
}